import React, { useState, useEffect } from "react";
import { marked } from "marked";
import Container from "@mui/material/Container";
import { fetcher } from "./fetcher";

export interface DocMarkdownProps {
  fileName: string;
}

const DocMarkdown = ({ fileName }: DocMarkdownProps) => {
  const [data, setData] = useState("");

  useEffect(() => {
    fetcher(`/static/media/${fileName}.md`)
      .then((response) => {
        if (!response) {
          throw new Error("Response is null");
        }
        return response.text();
      })
      .then((text) => {
        setData(marked.parse(text, { sanitize: true }));
      })
      .catch((err) => {
        console.log("md load", err);
      });
  }, [fileName]);

  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </Container>
  );
};

export default DocMarkdown;
