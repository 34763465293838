import React, { useState, useRef, useEffect, KeyboardEvent } from "react";
import Highlight from "react-highlight";
import {
  Box,
  Divider,
  TextField,
  List,
  ListItem,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import { IMessage, MessageTypes, ITarget, IGuess, IRagQuestion, IResponse, IQuery } from "./types"; // Define these types based on your needs
import { DataGrid, GridRowSelectionModel, GridRowId } from "@mui/x-data-grid";
import { fetcher, fetchJson } from "./fetcher";
import { useTheme, Theme } from "@mui/material/styles";
import $ from "jquery";

const randomID = (): string => {
  return Math.random().toString(36).substr(2, 9);
};

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [inputText, setInputText] = useState("");
  const listRef = useRef<HTMLUListElement>(null);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [datagridAnswers, setDatagridAnswers] = useState<{ [key: number]: GridRowId | undefined }>(
    {},
  );
  const [ragQuestions, setRagQuestions] = useState<IRagQuestion[]>([]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: "smooth" });
    }
  }, [messages]);

  const answerUserQuestion = async (question: IRagQuestion) => {
    setIsLoading(true);
    var cleanQuestion: IRagQuestion = structuredClone(question);
    for (const [key, value] of Object.entries(datagridAnswers) as number[][]) {
      cleanQuestion.targets[key].guesses = [question.targets[key].guesses[value]];
      cleanQuestion.targets[key].guesses[0].score = 100;
    }

    let hasAnswer: boolean = false;
    for (let i = 0; i < 5; i++) {
      let json;
      try {
        json = await fetchJson("/api/talker", "POST", cleanQuestion);
      } catch (e) {
        continue;
      }
      console.log("IResponse talker", json);
      let response = json as IResponse;
      if (response.success == false) {
        continue;
      }
      const query = response.message as IQuery;

      try {
        json = await fetchJson("/api/rds", "POST", query);
      } catch (e) {
        continue;
      }
      console.log("IResponse rds", json);
      response = json as IResponse;
      if (!response.success) {
        continue;
      }
      let result = response.message;
      setIsLoading(false);
      setIsInputDisabled(false);

      console.log("rds result", result);
      const newMessage: IMessage = {
        type: "talkerDatagrid",
        id: randomID(),
        content: { ragQuestion: cleanQuestion, query: query.query, result: result },
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      hasAnswer = true;
    }
    if (!hasAnswer) {
      setIsLoading(false);
      setIsInputDisabled(false);
      const newMessage: IMessage = {
        type: "text",
        content: "Something bad happened :(",
        isUser: false,
        id: randomID(),
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    }
  };

  useEffect(() => {
    console.log("datagridAnswers effect", datagridAnswers);
    var isAnswersEmpty: boolean = true;
    for (const [key, value] of Object.entries(datagridAnswers)) {
      isAnswersEmpty = false;
      if (value === undefined) {
        setIsInputDisabled(true);
        return;
      }
    }
    if (isAnswersEmpty) {
      return;
    }

    answerUserQuestion(ragQuestions[ragQuestions.length - 1]);
  }, [datagridAnswers]);

  const sendMessage = (text: string) => {
    $(".MuiDataGrid-main input").prop("disabled", true);
    for (var message of messages) {
      message.disabled = true;
    }
    setDatagridAnswers({});
    const newMessage: IMessage = { type: "text", content: text, isUser: true, id: randomID() };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputText("");
    setIsLoading(true);
    fetchJson("/api/rag", "POST", { product: "zabbix", payload: text })
      .then((json) => {
        setIsLoading(false);
        console.log("IResponse", json);
        const response = json as IResponse;
        if (response.success == false) {
          return null;
        }
        let blockInput = false;
        const ragQuestion = response.message as IRagQuestion;
        // ragQuestion.targets = [];

        Array.from(ragQuestion.targets.entries()).forEach(([target_index, target]) => {
          blockInput = true;
          const newMessage: IMessage = {
            type: "datagrid",
            id: randomID(),
            target_index: target_index,
            content: target,
            isUser: false,
          };
          setDatagridAnswers((prev) => ({
            ...prev,
            [target_index as number]: undefined,
          }));
          console.log("new message", newMessage);

          setMessages((prevMessages) => [...prevMessages, newMessage]);
        });
        setRagQuestions((prevQuestions) => [...prevQuestions, ragQuestion]);
        if (blockInput) {
          setIsInputDisabled(true);
        }
        if (ragQuestion.targets.length == 0) {
          answerUserQuestion(ragQuestion);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Fetching error: ", error);
        const newMessage: IMessage = {
          type: "text",
          content: "Something bad happened :(",
          isUser: false,
          id: randomID(),
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && inputText.trim() !== "") {
      event.preventDefault();
      sendMessage(inputText.trim());
    }
  };

  const initExample = () => {
    setMessages([
      {
        type: "talkerDatagrid",
        id: randomID(),
        content: {
          result: [
            {
              problem_name: "Linux: Zabbix agent is not available (for 3m)",
              start_date: "1970-01-01T00:00:00.000Z",
            },
          ],
        },
        isUser: false,
      },
      { type: "text", content: "Hello, this is a text message!", isUser: false, id: randomID() },
      { type: "image", content: "https://via.placeholder.com/150", isUser: true, id: randomID() },
      {
        type: "datagrid",
        id: randomID(),
        isUser: false,
        content: {
          name: "hstgrp",
          user_input: "linux hosts",
          explanation: "Plural form 'hosts' indicates a group, falls under 'hstgrp'.",
          id_name: "groupid",
          guesses: [
            { score: 65, result: "Linux servers", id_: 2, field: null },
            { score: 62, result: "Discovered hosts", id_: 5, field: null },
            { score: 37, result: "Virtual machines", id_: 6, field: null },
            { score: 34, result: "Hypervisors", id_: 7, field: null },
            { score: 26, result: "Templates/Server hardware", id_: 11, field: null },
          ],
        },
      },
    ]);
  };

  // useEffect(() => {initExample()}, []);

  useEffect(() => {
    // Dummy call to warm up the lambdas
    fetcher("/api/dummy", "POST", {});
  }, []);

  const renderMessage = (message: IMessage) => {
    const background = message.isUser
      ? theme.palette.backgrounds.dark
      : theme.palette.backgrounds.light;
    return (
      <Box sx={{ borderRadius: "1ex", backgroundColor: background, flex: 1, padding: "2em" }}>
        {" "}
        {_renderMessage(message)}
      </Box>
    );
  };
  const _renderMessage = (message: IMessage) => {
    switch (message.type) {
      case "text":
        return <Typography>{message.content}</Typography>;
      case "image":
        return <img src={message.content as string} alt="chat-img" style={{ maxWidth: "100%" }} />;
      case "table":
        // Example for rendering table, replace with actual data structure
        if (message.content.length > 0) {
          return (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {Object.keys(message.content[0]).map((key, idx) => (
                      <TableCell key={idx}>{key}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(message.content as { [key: string]: string }[]).map((row, i) => (
                    <TableRow key={i}>
                      {Object.keys(row).map((key, idx) => (
                        <TableCell key={key}>{row[key]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        } else {
          return <Typography>The query did not return any row.</Typography>;
        }

      case "datagrid":
        let rows = [];
        const target = message.content as ITarget;

        let id = 0;
        for (const guess of target.guesses) {
          rows.push({ id: id, score: guess.score, result: guess.result, id_: guess.id_ });
          id++;
        }
        const headers = [
          { field: "score", headerName: "Score" },
          { field: "result", headerName: "Description", flex: 1 },
          { field: "id_", headerName: target.id_name },
        ];
        return (
          <Box style={{ width: "100%", flex: 1 }}>
            <Typography>You asked for {target.user_input}: </Typography>
            <DataGrid
              key={message.id}
              rows={rows}
              columns={headers}
              checkboxSelection
              disableMultipleRowSelection
              disableRowSelectionOnClick={message.disabled ? true : false} // handle the case where disabled is undefined
              onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => {
                setDatagridAnswers((prev) => ({
                  ...prev,
                  [message.target_index as number]: selectionModel[0],
                }));
              }}
              sx={{ flex: 1 }}
            />
          </Box>
        );

      case "talkerDatagrid":
        let i = 0;
        let new_rows = [];
        for (let result_row of message.content.result.rows) {
          let row: { [key: string]: string | number } = { id: i };
          for (let j = 0; j < result_row.length; j++) {
            row[message.content.result.headers[j] as string] = result_row[j];
          }
          new_rows.push(row);
          i++;
        }
        let new_headers = [];
        for (let header of message.content.result.headers) {
          new_headers.push({ field: header, headerName: header, flex: 1 });
        }
        console.log("message content", message.content);
        return (
          <Box style={{ width: "100%", flex: 1 }}>
            <Typography>
              You selected
              {(message.content.ragQuestion.targets as ITarget[]).map(
                (target) => " " + target.guesses[0].result,
              )}
              .
            </Typography>
            <Divider />
            <Highlight className="sql">{message.content.query}</Highlight>
            <Divider />

            {message.content.result.rows.length == 0 ? (
              <Typography>The query did not return any row.</Typography>
            ) : (
              <DataGrid key={message.id} rows={new_rows} columns={new_headers} sx={{ flex: 1 }} />
            )}
          </Box>
        );

      default:
        console.log("unsupported message type", message.type);
        return <Typography>Unsupported message type</Typography>;
    }
  };

  return (
    <Box sx={{ height: "88vh", display: "flex", flexDirection: "column", padding: "3em" }}>
      <Box sx={{ flex: 1, overflowY: "auto" }} ref={listRef}>
        <List>
          {messages.map((msg, index) => (
            <ListItem key={index}>{renderMessage(msg)}</ListItem>
          ))}
          <ListItem key="loader">
            {" "}
            {isLoading && <Skeleton variant="rounded" sx={{ height: "3em", width: "100%" }} />}{" "}
          </ListItem>
        </List>
      </Box>
      <Box sx={{ flexShrink: 0 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="How many linux servers are monitored?"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={isInputDisabled}
        />
      </Box>
    </Box>
  );
};

export default Chat;
